import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import ClickableImage from "../components/ClickableImage";
import { Section, Container } from "../components/Markup";
import { withBreakpoints } from 'gatsby-plugin-breakpoints';

const ContactPage = (
  {
    data: {
      markdownRemark: {
        html,
        frontmatter: {
          bgImage,
          email,
          phone
        }
      }
    },
    breakpoints: {mobile, tablet}
  }) => {

  const links = [
    {
      url: `tel:${phone}`,
      shape: (<rect id="phone" fillOpacity={0} fill="#4A90E2"
                    transform="translate(1392.765183, 1325.831430) rotate(4.000000) translate(-1392.765183, -1325.831430) "
                    x="942.765183" y="1252.83143" width="900" height="146"></rect>)
    },
  ];

  return (
    <Layout fullWidth>
      <Section className={`${mobile || tablet ? 'px-0 py-0' : ''}`}>
        <Container>
          <HTMLContent content={html}/>
          <ClickableImage links={links} bgImage={bgImage}/>
        </Container>
      </Section>
    </Layout>
  )
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withBreakpoints(ContactPage)

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        phone
        email
        bgImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`;
